import { getFunctions, httpsCallable } from "firebase/functions";
import { log } from "../../helper/helper";
import config from "./../../config.json";


export function adminAllRidersChangeBounesPercentage(bonusFees, reason) {
    let data = {
        riders_bonus_on_fees: bonusFees,
        riders_bonus_reason_description: reason
    };
    log(data);
    const functions = getFunctions();
    const changeBounes = httpsCallable(functions, config.adminAllRidersChangeBounesPercentage);
    return changeBounes(data);
}