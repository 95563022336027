import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Spinner from "../common/spinner";
import { ToastContainer, toast } from "react-toastify";
import "./batchRiderBonusUpdate.scss";
import Input from "../common/input";

import * as service from "./batchRiderBonusUpdateService";
import { log } from "../../helper/helper";
import { Button } from "react-bootstrap";

function BatchRiderBonusUpdate(props) {
  const [isLoading, setLoading] = useState(false);
  const [newRiderBonus, setNewRiderBonus] = useState("");
  const [reasonUpdate, setReasonUpdate] = useState("");

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        return props.history.replace("/login");
      }
    });
  }, []);

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };

  const handleSetNewRiderBonus = (inputValue) => {
    if (!/^\d*$/.test(inputValue)) return;
    const numberValue = Number(inputValue);
    if (numberValue >= 0 && numberValue <= 100) {
      setNewRiderBonus(inputValue);
    } else if (inputValue === "") {
      setNewRiderBonus("");
    }
  };

  const handleSubmit = async () => {
    if (newRiderBonus.replace(/\s+/g, "").length === 0) {
      toast.error("Bonus must be a Number between 0 and 100");
      return;
    }

    if (reasonUpdate.replace(/\s+/g, "").length < 3) {
      toast.error("Reason Note must have more than 2 characters");
      return;
    }

    setLoading(true);
    try {
      const result = await service.adminAllRidersChangeBounesPercentage(
        newRiderBonus,
        reasonUpdate
      );
      log(result);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            setReasonUpdate("");
            setNewRiderBonus("");
            return toast.success(result.data.message);

          case 401:
            toast.error(result.data.message);
            return props.history.replace("/login");
          case 500:
            return toast.error(result.data.message);
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  return (
    <div className="show-all-list batch-rider-bonus-update">
      <ToastContainer />
      {renderSpinner()}
      <div className="row">
        <div className="col-md-12">
          <Input
            label="New Rider Bonus: ( 0% ~ 100% )"
            type="String"
            name="title"
            value={newRiderBonus}
            onChange={(e) => handleSetNewRiderBonus(e.target.value)}
          />
        </div>
        <div className="col-md-12" style={{ marginTop: 25 }}>
          <Input
            label="Reason for update:"
            type="String"
            name="ReasonForIUpdate"
            value={reasonUpdate}
            onChange={(e) => setReasonUpdate(e.target.value)}
          />
        </div>
      </div>
      <div className="row mg-custom">
        <div className="col-md-12" style={{ marginTop: 50 }}>
          <Button
            className="batch-update btn-blue"
            onClick={() => handleSubmit()}
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  );
}
export default BatchRiderBonusUpdate;
